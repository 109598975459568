import React from "react";
import { Link } from "react-router-dom";

// import Logo from "../../../assets/images/logo.svg";
import NewLogo from "../../../assets/images/AIms-Logo.jpg";
// import Bell from '../../../assets/images/bell.svg';
import User from "../../../assets/images/user.svg";

import alertService from "../../../services/alert";
import { AuthConsumer } from "../../../context/AuthContext";

class Header extends React.Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleMouseEnter = () => {
    this.handleOpen();
  };

  handleMouseLeave = () => {
    this.handleClose();
  };

  notificationClicked() {
    alertService.inDevelopmentAlert("Notifications");
  }

  profileClicked() {
    alertService.inDevelopmentAlert("Profile");
  }

  render() {
    const { open } = this.state;
    return (
      <header>
        <div className="header-left">
          <div className="logo">
            <Link to="/tournaments">
              <img src={NewLogo} alt="" />
              <span>AIMS</span>
            </Link>
          </div>
        </div>
        <div className="header-right">
          {/* <div className="notification">
                        <button className="link" onClick={this.notificationClicked}><img src={Bell} alt="" /></button>
                    </div> */}
          <div
            className={`user ${open ? "hover" : ""}`}
            onClick={() => this.handleOpen()}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <button className="link">
              <img src={User} alt="" />
            </button>
            <div className="dropdown dropdown-position-bottom-left dropdown-fully-radius dropdown-menu">
              <AuthConsumer>
                {({ user, signOut }) => (
                  <div className="dropdown-inner">
                    <div className="profile-link">
                      <span className="profile-link-photo">
                        <img src={User} alt="" />
                      </span>
                      <span className="profile-link-name">{user.name}</span>
                      <span className="profile-link-description">
                        {user.email} <br />
                        {user.schoolName}
                      </span>
                    </div>
                    <div className="dropdown-section">
                      <div className="dropdown-item" onClick={signOut}>
                        <span className="icon-log-out dropdown-item-icon"></span>
                        <div className="dropdown-item-label">Logout </div>
                      </div>
                    </div>
                  </div>
                )}
              </AuthConsumer>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
