import React from "react";

const TableRow = (props) => (
  <div className={`row row-small ${props.className}`}>
    <div className="cols w-20">{props.schoolName}</div>
    <div className="cols w-20">{props.name}</div>
    <div className="cols w-10">{props.gender}</div>
    <div className="cols w-10">{props.id}</div>
    <div className="cols w-20">{props.score}</div>
    <div className="cols w-15">{props.numTens}</div>
    <div className="cols w-25">{props.teamScore}</div>
  </div>
);

// const tempData = [
//   {
//     externalId: '1515',
//     gender: 'M',
//     name: 'Steve',
//     schoolId: 5,
//     schoolName: 'Test 1',
//     tournamentSession: {
//       totalScore: 100,
//       numTens: 7,
//     }
//   },
//   {
//     externalId: '1516',
//     gender: 'F',
//     name: 'Sarah',
//     schoolId: 5,
//     schoolName: 'Test 1',
//     tournamentSession: {
//       totalScore: 60,
//       numTens: 5,
//     }
//   },
//   {
//     externalId: '1517',
//     gender: 'M',
//     name: 'Matt',
//     schoolId: 5,
//     schoolName: 'Test 1',
//     tournamentSession: {
//       totalScore: 100,
//       numTens: 8,
//     }
//   },
//   {
//     externalId: '1518',
//     gender: 'M',
//     name: 'Jeff',
//     schoolId: 5,
//     schoolName: 'Test 1',
//     tournamentSession: {
//       totalScore: 10,
//       numTens: 1,
//     }
//   },
//   {
//     externalId: '1615',
//     gender: 'F',
//     name: 'Linda',
//     schoolId: 1,
//     schoolName: 'Test 2',
//     tournamentSession: {
//       totalScore: 70,
//       numTens: 4,
//     }
//   },
//   {
//     externalId: '1616',
//     gender: 'M',
//     name: 'Tom',
//     schoolId: 1,
//     schoolName: 'Test 2',
//     tournamentSession: {
//       totalScore: 70,
//       numTens: 2,
//     }
//   },
//   {
//     externalId: '1617',
//     gender: 'M',
//     name: 'Mike',
//     schoolId: 1,
//     schoolName: 'Test 2',
//     tournamentSession: {
//       totalScore: 100,
//       numTens: 6,
//     }
//   },
//   {
//     externalId: '1618',
//     gender: 'M',
//     name: 'Fred',
//     schoolId: 1,
//     schoolName: 'Test 2',
//     tournamentSession: {
//       totalScore: 10,
//       numTens: 0,
//     }
//   },
// ];

export const TournamentResults3d = (props) => {
  const schoolIds = [];
  const teamScores = [];
  const students = [];

  props.data.forEach((student) => {
    if (!schoolIds.includes(student.schoolId)) {
      schoolIds.push(student.schoolId);
    }
  });

  schoolIds.forEach((id) => {
    let tempStudents = [];
    props.data.forEach((student) => {
      if (student.schoolId === id) {
        tempStudents.push(student);
      }
    });
    let tempSortedStudents = tempStudents.sort((a, b) => b.tournamentSession.totalScore - a.tournamentSession.totalScore);
    let teamScore = 0;
    tempSortedStudents.forEach((student, index) => {
      if (index < 3 && student.schoolId === id) {
        teamScore = teamScore + student.tournamentSession.totalScore;
      }
    });
    teamScores.push({ schoolId: id, teamScore });
  });

  props.data.forEach((student) => {
    teamScores.forEach((scoreObj) => {
      if (scoreObj.schoolId === student.schoolId) {
        student = {...student, teamScore: scoreObj.teamScore};
        students.push(student);
      }
    });
  });

  const sorted = students.sort(
    function(a, b) {
      if (a.teamScore === b.teamScore && a.tournamentSession.totalScore === b.tournamentSession.totalScore) {
        return b.tournamentSession.numTens - a.tournamentSession.numTens;
      }
      if (a.teamScore === b.teamScore && a.tournamentSession.totalScore !== b.tournamentSession.totalScore) {
        return b.tournamentSession.totalScore - a.tournamentSession.totalScore;
      }
      return b.teamScore - a.teamScore;
    }
  );

  return (
    <>
      {sorted.length > 0 && (
        <>
          <div className="table-view table-separator-spacer">
            <TableRow
              className="head-row head-row-bg"
              schoolName="School Name"
              name="Archer Name"
              gender="Gender"
              id="Archer ID"
              score="Personal Score"
              numTens="Number of 10's"
              teamScore="Team Score (Top 3 / team)"
            />
            {sorted.map((student, index) => (
              <TableRow
                key={index}
                schoolName={student.schoolName}
                name={student.name}
                gender={student.gender}
                id={student.externalId}
                score={student.tournamentSession.totalScore}
                numTens={student.tournamentSession.numTens}
                teamScore={student.teamScore}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};
