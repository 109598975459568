import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { parseISO } from "date-fns";
import TournamentService from "../../../services/TournamentService";
import alertService from "../../../services/alert";

import "react-datepicker/dist/react-datepicker.css";
import { dateToAPIFormat } from "../../../util/helpers";

class CreateTournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournament: props.tournament ?? {
        name: null,
        externalId: null,
        location: null,
        ownerId: null,
        startDate: dateToAPIFormat(new Date()),
        endDate: dateToAPIFormat(new Date()),
      },
      scoringFormatError: false,
      typeError: false,
      nameError: false,
      dateError: false,
      idError: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleScoringFormatChange = this.handleScoringFormatChange.bind(this);

  }

  validate() {
    var success = true;
    var { tournament } = this.state;

    if (!tournament.name || tournament.name.trim() === "") {
      this.setState({
        nameError: true,
      });
      success = false;
    } else {
      this.setState({
        nameError: false,
      });
    }

    if (
      tournament.isVirtual === undefined ||
      tournament.isVirtual === "default"
    ) {
      this.setState({
        typeError: true,
      });
      success = false;
    } else {
      this.setState({
        typeError: false,
      });
    }
    if (tournament.isBulzi === undefined || tournament.isBulzi === 'default') {
      this.setState({
          scoringFormatError: true
      });
      success = false;
  } else {
      this.setState({
          scoringFormatError: false
      });
  }
    if (tournament.endDate.valueOf() > tournament.startDate.valueOf()) {
      this.setState({
        dateError: false,
      });
    } else {
      this.setState({
        dateError: true,
      });
      success = false;
    }
    return success;
  }

  submit() {
    if (this.validate()) {
      this.saveToServer();
    }
  }

  async saveToServer() {
    const { tournament } = this.state;
    let tournamentToSave = tournament;
    if (!this.props.edit)
      tournamentToSave = { ...tournament, ownerId: this.props.user.userId};

    const service = await TournamentService.getInstance();
    const response = await service.saveTournament(tournamentToSave);

    if (response.success) {
      this.props.onClosePanel(true);
    } else {
      alertService.show({
        title: "Save Failed",
        message:
          "Unable to save Tournament details at this time, please try again.",
      });
    }
  }

  handleNameChange({ target: { value } }) {
    var { tournament } = this.state;
    tournament.name = value.trim().length === 0 ? null : value;

    this.setState({
      tournament: tournament,
    });
  }

  checkExternalIdExists = async () => {
    var { tournament } = this.state;
    if (tournament.externalId) {
      const service = await TournamentService.getInstance();
      const response = await service.checkExternalIdInTournament(
        tournament.externalId
      );
      if (response.success === true) {
        const { tournamentId } = response.data;
        if (
          tournament.tournamentId &&
          tournament.tournamentId === tournamentId
        ) {
          this.setState(
            {
              idError: false,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else if (
          tournament.tournamentId &&
          tournament.tournamentId !== tournamentId
        ) {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        } else {
          this.setState(
            {
              idError: true,
            },
            () => {
              !this.state.idError && this.submit();
            }
          );
        }
      } else {
        this.setState(
          {
            idError: false,
          },
          () => {
            !this.state.idError && this.submit();
          }
        );
      }
    } else {
      this.submit();
    }
  };

  handleIdChange({ target: { value } }) {
    var { tournament } = this.state;
    tournament.externalId = value.trim().length === 0 ? null : value;
    this.setState({
      tournament: tournament,
    });
  }

  handleLocationChange({ target: { value } }) {
    var { tournament } = this.state;
    tournament.location = value.trim().length === 0 ? null : value;

    this.setState({
      tournament: tournament,
    });
  }

  handleTypeChange({ target: { value } }) {
    var { tournament } = this.state;
    tournament.isVirtual = value;

    this.setState({
      tournament: tournament,
    });
  }

  handleScoringFormatChange({ target: { value } }) {
    var { tournament } = this.state;

    if (value === 'false') {
      tournament.isBulzi = false;
      tournament.is3d = false;
    } else if (value === 'true') {
      tournament.isBulzi = true;
      tournament.is3d = false;
    } else if (value === '3d') {
      tournament.is3d = true;
      tournament.isBulzi = false;
    }

    this.setState({
        tournament: tournament
    });
}

  handleStartChange = (date) => {
    var { tournament } = this.state;
    tournament.startDate = dateToAPIFormat(date);
    if (moment(tournament.endDate).isBefore(tournament.startDate)) {
      tournament.endDate = tournament.startDate;
    }
    this.setState({
      tournament: tournament,
    });
  };

  handleEndChange = (date) => {
    var { tournament } = this.state;
    tournament.endDate = dateToAPIFormat(date);
    this.setState({
      tournament: tournament,
    });
  };

  render() {
    const { tournament, nameError, typeError, scoringFormatError, dateError, idError } = this.state;
    return (
      <section className="sidebar visible" id="add-tournament">
        <div className="sidebar-wrapper">
          <h3>{this.props.edit === true ? "Edit" : "Add New"} Tournament</h3>
          <div className="sidebar-content-wrapper">
            <ul>
              <li>
                <label>Tournament Name *</label>
                <input
                  type="text"
                  placeholder="Enter tournament name"
                  value={tournament.name ?? ""}
                  onChange={this.handleNameChange}
                />
                {nameError === true && (
                  <div className="error">Name required</div>
                )}
              </li>
              <li>
                <label>Tournament ID</label>
                <input
                  type="text"
                  placeholder="Enter tournament ID"
                  value={tournament.externalId ?? ""}
                  onChange={this.handleIdChange}
                />
                {idError ? (
                  <div className="error">ID already exists</div>
                ) : null}
              </li>
              <li>
                <label>Tournament Type*</label>
                <select
                  id="tournament-type"
                  onChange={this.handleTypeChange}
                  value={tournament.isVirtual ?? ""}
                >
                  <option value="default">Choose type</option>
                  <option value={false}>Live</option>
                  <option value={true}>Virtual</option>
                </select>
                {typeError === true && (
                  <div className="error">Type required</div>
                )}
              </li>
              <li>
                <label>Scoring Format*</label>
                <select
                  id="scoring-format"
                  onChange={this.handleScoringFormatChange}
                  value={tournament.isBulzi ? true : tournament.is3d ? '3d' : ''}
                >
                    <option value="default">Choose type</option>
                    <option value={false}>School Archery</option>
                    <option value={true}>Bullseye/Individual</option>
                    <option value={'3d'}>3D Outdoor/Xtreme</option>
                </select>
                {scoringFormatError === true &&
                  <div className="error">Scoring format required</div>
                }
              </li>
              <li>
                <label>Location</label>
                <input
                  type="text"
                  placeholder="Enter address"
                  id="tournament-location"
                  value={tournament.location ?? ""}
                  onChange={this.handleLocationChange}
                />
              </li>
              <li>
                <label>Date & Time</label>
                <div className="date-time" id="DateTimePair">
                  <div className="w-50">
                    <DatePicker
                      selected={parseISO(tournament.startDate)}
                      onChange={this.handleStartChange}
                      showTimeInput
                      dateFormat="MM/dd/yyyy h:mm aa"
                      popperPlacement="top-start"
                      minDate={parseISO(dateToAPIFormat(new Date()))}
                    />
                  </div>

                  <div className="w-50">
                    <DatePicker
                      selected={parseISO(tournament.endDate)}
                      onChange={this.handleEndChange}
                      showTimeInput
                      dateFormat="MM/dd/yyyy h:mm aa"
                      popperPlacement="top-start"
                      minDate={parseISO(tournament.startDate)}
                      minTime={parseISO(tournament.startDate)}
                    />
                  </div>
                </div>
                <br />
                <br />
                {dateError === true && (
                  <div className="error">
                    End Date should be greater than start date
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="button-group">
            <button
              onClick={() => this.props.onClosePanel(false)}
              className="btn btn-cancel"
            >
              Cancel
            </button>
            <button
              onClick={() => this.checkExternalIdExists()}
              className="btn"
            >
              Save
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default CreateTournament;
