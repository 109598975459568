import React, { useEffect, useState } from "react";
import ErrorState from "../common/errorState/ErrorState";
import Loading from "../common/loading/Loading";
import SchoolService from "../../services/SchoolService";
import { TournamentResults3d } from "./TournamentResults3d";

export const TournamentStudents3d = (props) => {
  const tournamentId = props.match.params.tournamentId;
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchStudents(tournamentId);
  }, [tournamentId]);

  const fetchStudents = async (tournamentId) => {
    const service = await SchoolService.getInstance();
    const response = await service.getStudentsForTournament(tournamentId);
    if (response.success) {
      setData(response.data);
    } else {
      setError(true);
    }
  };

  if (data) {
    return (
      <div className="tabs-wrapper">
        <TournamentResults3d
          data={data}
        />
      </div>
    );
  } else if (error) {
    return (
      <div className="tabs-wrapper">
        <ErrorState title="An error has occurred while loading this content. Please try again." />
      </div>
    );
  } else {
    return (
      <div className="tabs-wrapper">
        <Loading title="Loading..." />
      </div>
    );
  }
};
